import React from 'react';
import './GeneralButton.css';
export const GeneralButton = ({
  onclick,
  text,
  type,
  disabled,
  customClass,
}) => {
  return (
    <button
      onClick={onclick}
      type={type}
      disable={disabled}
      className={customClass || 'Button'}
    >
      {text}
    </button>
  );
};
