export const testimonials = {
  'Emma Romasco, Voice Actor & Coach':
    "With KZ, where there is a will, there's a way! It's been an absolute pleasure to see KZ blossom into the talent that she is, all through her dedication and perseverance when it comes to her craft. Whether it's a soft sweet sound, or contemporary quirk, KZ has the reads you need for all things VO!",
  'Kim Alu, Director, Writer, Improv/Sketch Coach':
    'I’ve had the pleasure of working with KZ over the last year on a multitude of live and filmed projects. With each new project KZ brings a fresh, fun, and most importantly, funny take on any character she plays. She has the ability to take the wackiest characters and ground them in reality and vulnerability. She has the ability to take a devastating scene and still make you smile. Her talent is without question.',
  'Carlos Narvaez, Actor':
    "KZ is a tour de force and inspiring artist who you don't want to miss. Your eyes will be drawn to her from the moment she appears on stage!",
  'Fiona W., Audience Member':
    'I never knew I liked improv until I saw KZ do it! Her style is engaging, so accessible, and hilarious in a subtle, unique way - I felt completely transported into the scene when I watched her perform.',
  'Rachel L., Filmmaker':
    'KZ is a pleasure to work with: her comedic instincts, timing, and acting are awesome!',
  'Savannah Kinzer, Actor':
    'KZ is a captivating performer in her economy of movement and emotional depth. Strikingly beautiful and brave, she’s fearless!',
  'Betty F., Audience Member':
    "KZ's improv show was the first one I had gone to since college, and I did not know what to expect. KZ's performance was so emotive and powerful, showing range from loveliness to being truly unhinged. I would definitely go again to gape, gasp, and of course, laugh.",
  'James Navan, Improviser':
    'KZ knows how to stay grounded yet always interesting, and quite often outrageous. And, outrageously funny!',
  'Jon Chan, Actor':
    'I had the opportunity to take classes and perform in a play with KZ, and she consistently impressed and surprised me with her incredible acting skills. She brings authenticity and depth to her roles, and her dedication to her craft is evident. Working with KZ was both a privilege and a joy.',
  'Jessy Tomsko, Musician, Composer, Teaching Artist':
    'I enjoy working with her so much, and I truly appreciate her willingness to learn and work on such a wide variety of repertoire. Her voice has grown so much through singing classical music, pop, and musical theatre repertoire, and I am especially thrilled with her note-reading progress and ability to sight-sing. KZ is a solid alto/mezzo-soprano, with a lovely high extension that is developing in a very exciting way, and her confidence in her singing has grown tremendously. I am so pleased to work with her, and hope to continue for a long while to come!',
  'Jeremy Radick, Actor':
    'I had the pleasure of studying with KZ in a year-long Meisner intensive class, which was a challenging and demanding process, but very rewarding. Whether I worked with her in a scene, an exercise, or just observed her work with others, I was constantly amazed and impressed by her commitment and by her dedication; she regularly blew us all away with her innovative and fearless choices. Her investment as an actor raised the bar for all of us to work harder and go deeper. I genuinely loved seeing her work, and looked forward to seeing what she would bring to the work and to her ideas as a performer. I would love to work or study with her again anytime!',
  'Kaughlin Caver, Actor':
    'I had the pleasure of working with KZ in an acting class, and she quickly became one of my favorite people to collaborate with. She brings a level of authenticity and depth to her work that is rare. I also greatly appreciate the effort KZ puts into her craft. Her attention to detail and passion are truly inspiring and has pushed me to improve in my own work.',
  'Melissa Takai, Actor':
    'I trained with KZ at Freehold Theatre and was always inspired to watch how committed of an actor she was during scene work. KZ has such bold and artistic visions for her projects, and always works the extra mile to bring it to life.',
};
