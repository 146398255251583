import './About.css';
export const About = () => {
  return (
    <div className="About-body">
      <div className="About-header">About KZ</div>
      <div className="Paragraph-container">
        <p className="Paragraph">
          A native New Yorker, KZ Daisy is an Asian American actor, improviser,
          and voiceover artist based in New York City. She has performed theater
          at Freehold Theatre, improv and sketch comedy at Magnet Theater and
          The Peoples Improv Theater (The PIT), and spoken word at The Asian
          American Writers' Workshop, Yale University, and Bowery Poetry. With
          her improv house team <span>The 1%</span>, KZ performs live every
          other week at The PIT. Her first short film <span>Fishlighting </span>
          premieres this fall on Asian American Film Lab TV.
        </p>
        <p className="Paragraph">
          She is a grateful alumnus of the Meisner, Core Acting, and improv
          progressions at Freehold Theatre, where she played Meg Magrath in a
          full-length production of <span>Crimes of the Heart</span>. An eternal
          student, KZ has also trained in voice acting at Abacus Entertainment,
          Atlanta Voiceover Studio, Real Voice LA, Seattle Voice Academy,
          Voiceover Camp, and VO Heaven; improv and sketch comedy at Magnet
          Theater, The PIT, and Unexpected Productions; on-camera at MN Acting
          Studio; voice at Jessy Tomsko Music; and clown at Freehold Theatre;
          among others.
        </p>
        <p className="Paragraph">
          KZ's creative inspirations include daily life and relationships, Asian
          American vloggers, reality TV, Ali Wong, and romance novels. She lives
          with her husband and two amazing cats.
        </p>
      </div>
      <div className="Image-container">
        <img className="Image" alt="pic1" src="pic1.jpeg" />
        <img className="Image" alt="pic2" src="pic2.jpeg" />
        <img className="Image" alt="pic3" src="pic3.jpeg" />
      </div>
    </div>
  );
};
