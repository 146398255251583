import React from 'react';
import { testimonials } from './testimonialObj';
import './Testimonials.css';
import { GeneralButton } from './GeneralButton';
export const Testimonials = ({ sentences }) => {
  const authors = Object.keys(testimonials);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className="Test-body">
      <div className="Keywords">
        Grounded, Inspiring, Comedic, Emotional, Fearless
      </div>
      {authors.map((curAuthor) => {
        return (
          <div className="Test-container">
            <div className="Testimonial-p">{testimonials[curAuthor]}</div>
            <div className="Testimonial-a">—{curAuthor}</div>
          </div>
        );
      })}
      <GeneralButton
        customClass="Scroll-to-top"
        text="↑"
        onclick={scrollToTop}
      />
      {/* <button onClick={scrollToTop} className="Scroll-to-top">
        ^
      </button> */}
    </div>
  );
};
