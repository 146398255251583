import { useForm, ValidationError } from '@formspree/react';
import './Contact.css';
import { GeneralButton } from './GeneralButton';
export const Contact = () => {
  const [state, handleSubmit] = useForm('mdknokgq');
  if (state.succeeded) {
    return <p>We'll be in touch soon!</p>;
  }
  return (
    <div>
      <div className="Contact"> Contact</div>
      <form className="Contact-form-container" onSubmit={handleSubmit}>
        <div className="Name-container">
          <input
            className="Name-input"
            placeholder="Name"
            id="name"
            name="name"
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
        </div>
        <div className="Contact-email-container">
          <input
            className="Email-input"
            placeholder="Email"
            id="email"
            type="email"
            name="email"
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>
        <div>
          <textarea
            className="Message-container"
            placeholder="Message"
            id="message"
            name="message"
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
        </div>
        <GeneralButton
          type="submit"
          disabled={state.submitting}
          text="Submit"
        />
      </form>
    </div>
  );
};
