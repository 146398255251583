import './Navbar.css';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBlog } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { useLocation } from 'react-router-dom';
export const Navbar = () => {
  return (
    <div className="Navbar-body">
      <div className="Left-navbar">
        <a href="./about">
          <div className="Name">KZ Daisy</div>
        </a>
        <div className="Icon-container">
          <a href="https://www.instagram.com/thekzdaisy/">
            <FontAwesomeIcon className="Logos" icon={faInstagram} />
          </a>
          <a href="https://www.youtube.com/@kz_daisy">
            <FontAwesomeIcon className="Logos" icon={faYoutube} />
          </a>
          <a href="https://kzdaisy.substack.com/">
            <FontAwesomeIcon className="Logos" icon={faBlog} />
          </a>
        </div>
      </div>

      <div className="Nav-container">
        <a
          id="about"
          className={
            useLocation().pathname === '/about'
              ? 'Selected-nav-links'
              : 'Nav-links'
          }
          href="./about"
        >
          About
        </a>
        <a
          id="demo"
          className={
            useLocation().pathname === '/demo'
              ? 'Selected-nav-links'
              : 'Nav-links'
          }
          href="./demo"
        >
          Demo
        </a>
        <a
          id="studio"
          className={
            useLocation().pathname === '/studio'
              ? 'Selected-nav-links'
              : 'Nav-links'
          }
          href="./studio"
        >
          Studio
        </a>
        <a
          id="testimonials"
          className={
            useLocation().pathname === '/testimonials'
              ? 'Selected-nav-links'
              : 'Nav-links'
          }
          href="./testimonials"
        >
          Testimonials
        </a>

        <a
          className={
            useLocation().pathname === '/contact'
              ? 'Selected-nav-links'
              : 'Nav-links'
          }
          href="./contact"
        >
          Connect
        </a>
      </div>
    </div>
  );
};
