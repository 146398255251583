import './Studio.css';
export const Studio = () => {
  return (
    <div className="Studio-container">
      <div className="Header">KZ’s Studio Specs</div>
      <div className="List-Container">
        <div className="Studio-items">Microphone: Rode NT1</div>
        <div className="Studio-items">
          Audio Interface: Focusrite Scarlett 2i2
        </div>
        <div className="Studio-items">DAW: Audacity</div>
        <div className="Studio-items">Directed Sessions: Zoom</div>
      </div>
    </div>
  );
};
