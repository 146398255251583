import './App.css';
import { Navbar } from './Navbar.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Studio } from './Studio';
import { Demo } from './Demo.js';
import { About } from './About.js';
import { Testimonials } from './Testimonials.js';
import { Contact } from './Contact.js';
import { Footer } from './Footer.js';
function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <header className="App-header">
          <Navbar />
        </header>
        <main className="App-body">
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/about" element={<About />} />
            <Route path="/studio" element={<Studio />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
