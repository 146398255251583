import { useForm, ValidationError } from '@formspree/react';
import './Footer.css';
import { useLocation } from 'react-router-dom';
import { GeneralButton } from './GeneralButton';
export const Footer = () => {
  const isOnContact = useLocation().pathname === '/contact';
  const [state, handleSubmit] = useForm('mdknokgq');
  if (state.succeeded) {
    return <p>Thanks for Subscribing!</p>;
  }
  return (
    <div>
      <div className="Subscribe">Newsletter</div>
      <form className="Form-container" onSubmit={handleSubmit}>
        <div className="Email-container">
          <input
            className="Email-input"
            placeholder="Email"
            id="email"
            type="email"
            name="email"
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>
        <div>
          <GeneralButton
            type="submit"
            text="Subscribe"
            disabled={state.submitting}
          />
        </div>

        {/* <button className="Submit" type="submit" disabled={state.submitting}>
          Subscribe
        </button> */}
      </form>
      {isOnContact && (
        <a href="https://kzdaisy.substack.com">
          <div className="Past-issues">Read past issues</div>
        </a>
      )}
    </div>
  );
};
