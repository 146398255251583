import React, { useRef } from 'react';

export const AudioPlayer = ({ audioSrc }) => {
  const audioRef = useRef(null);

  return (
    <div>
      <audio ref={audioRef} controls>
        <source src={audioSrc} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <br />
    </div>
  );
};
