import './Demo.css';
import { AudioPlayer } from './Audio.js';

export const Demo = () => {
  const audioFileUrl = '/KZDaisy-CommercialDemo.mp3';
  return (
    <div className="Demo-container">
      <div className="Placeholder">Commercial Demo (2024)</div>
      <div>
        <AudioPlayer audioSrc={audioFileUrl} />
      </div>
    </div>
  );
};
